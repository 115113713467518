.box {
  flex: 1;
  color: #fff;
  background: #2F334C;
  padding: 10px 20px;
  user-select: none;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box:hover {
  background: #E45423;
  color: #000;
}

.single_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title_box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 78%;

}

.title {
  font-size: 1vw;
  font-weight: normal;
  letter-spacing: 0px;
}



.subtitle {
  font-size: 0.625vw;
  font-weight: 500;
  line-height: 1.625vw;
  letter-spacing: 0px;
  opacity: 0.6;

}


.imgbox {
  width: 45px;
  height: 45px;
  background-size: 100% 100%;
  transition: all 0.5s;
}

.content_box {
  font-size: 0.73vw;
  font-weight: 600;
  letter-spacing: 0px;
  width: 78%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline {
  display: inline;
}

@media (max-width: 820px) {
  .box {
    padding: 0;
  }

  .title {
    font-size: 12px;
    line-height: 12px;
  }

  .imgbox {
    height: 10.66vw;
    width: 10.66vw;
  }

  .subtitle {
    font-size: 12px;
    line-height: 12px;
  }

  .content_box {
    font-size: 8px;
  }
}