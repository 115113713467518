* {
    margin: 0;
    padding: 0;
}

.App {
    text-align: center;
    background: #141623;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.bannerBox {
    width: 100%;
}

.container-fluid {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}

#wx {
    background: url('./assets/modal/modal.png');
    width: 42.45vw;
    height: 29.89vw;
    left: 50%;
    z-index: 1000;
    position: relative;
    background-repeat: no-repeat;
    background-size: 42.45vw 25.89vw;
    top: 50%;
    margin-left: -21.22vw;
    margin-top: -12.95vw;
}

#wx div {
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -25px;
}

#wx .close {
    width: 2.6vw;
    height: 2.6vw;
    cursor: pointer;
}

.App-header {
    padding: 0.93vw 18.5%;
    display: flex;
    align-items: center;
    color: #666;
    font-size: 0.83vw;
}

.head-title {
    line-height: 37px;
    margin-right: 3.125vw;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    color: inherit;
    line-height: inherit;
}

.head-title:visited,
.head-title:hover {
    text-decoration: none;
}

.head-title:hover {
    color: #E45423
        /* 继承父元素的字体颜色 */
}

.title-box {
    display: flex;
}

.single_title_box {
    margin-top: 8.125vw;
}

.single_title_inbox {
    padding-top: 3.28vw;
}

.single_title_inbox img,
.single_title_box img {
    width: 45vw;
}

.hot-class {
    position: absolute;
    left: 4.37vw;
    top: 6.875vw;
    width: 77vw;
    height: 32.55vw;
    background-image: url('./assets/hotclass/bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.hot-class-btn {
    position: absolute;
    left: 50%;
    width: 11.4vw;
    margin-left: -5.7vw;
    bottom: 1.35vw;
}

.hot-class-box {
    width: 44.6vw;
    position: absolute;
    left: 25.8vw;
    top: 10.45vw;
    display: flex;
    gap: 3.38vw;
    justify-content: space-between;
    height: 17.55vw;
}

/* 
.hot-class div {
  position: absolute;
  top: 17.5vw;
  width: 12.12vw;
  height: 15.94vw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}*/
.hot-class-box div {
    background-size: 100% auto;
    background-position-x: center;
    background-repeat: no-repeat;
    flex: 1;
    position: relative;
    transition: all 0.5s;
}

.hot-class-box div:hover {
    margin-top: -20px;
}

.hot-class-box div img {
    position: absolute;
    bottom: -15px;
    left: 50%;
    width: 90%;
    transform: translate(-50%, 0);
    backdrop-filter: blur(30px);
    border-radius: 10px;
}

#hotClass1 {
    background-image: url('./assets/hotclass/class1.png');

}

#hotClass2 {
    background-image: url('./assets/hotclass/class2.png');
}

#hotClass3 {
    background-image: url('./assets/hotclass/class3.png');
}

.hot-class-btn img {
    width: 100%;
}

.work_show_box img {
    width: 100%;
}

.jobs {
    margin: 3.38vw auto 1vw auto;
    display: flex;
    width: 60.6vw;
    gap: 10px;
}

.job-tips{
    text-align: right;
    width: 60.6vw;
    margin: 0 auto 2vw auto;
    color: #C7C7CC;
}

.jobs .left_box {
    flex: 1;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.jobs .right_box {
    flex: 5.49;

}

.jobs .right_box img {
    width: 100%;
}



.study_box {
    background-image: url('./assets/study/background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.teacher_box {
    background-image: url('./assets/teacher/background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.studys {
    display: flex;
    margin: 4vw auto 0 auto;
    width: 60vw;
    gap: 7vw;
    color: #C7C7CC;
    text-align: center;
}

.studys div {
    flex: 1;
}

.studys div img {
    width: 100%;
}

.study_title {
    margin-top: 2.44vw;
    margin-bottom: 1.46vw;
    font-size: 30px;
}

.teacher_carousel {
    width: 67vw;
    margin: 4.3vw auto 0 auto;
    padding-bottom: 6vw;
}

.study_subtitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6vw;
}

.about_us {
    height: 42.5vw;
    position: relative;
    background-image: url('./assets/aboutus/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.about_us_box {
    width: 68.65vw;
    height: 19.9vw;
    background-image: url('./assets/aboutus/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 15.675vw;
    top: 16.875vw
}

.about_us_person {
    position: absolute;
    bottom: 0;
    left: 5.5vw;
    width: 17.55vw;
}

.about_us_content {
    width: 43vw;
    position: absolute;
    top: 0.6vw;
    left: 25vw;
    color: #FFFFFF;
    line-height: 2vw;
    text-align: left;
}

.about_us_content img {
    width: 26.56vw;
}

.about_us_content div {
    font-size: 0.83vw;
    margin-left: 2vw;
    font-weight: normal;
}

.company_box {
    margin: 5.7vw auto 5.8vw auto;
    width: 55.78vw;
}

.company_box img {
    width: 100%;
}

.first-head-title {
    margin-left: 1.71vw;
}

.head-title-active {
    color: #E45423;
    border-bottom: 3px solid #E45423;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.foot-img {
    width: 100%;
}

.primary-btn {
    font-size: 0.9vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 25px;
    width: 15vw;
    height: 49px;
    background: #000000;
    border-radius: 25px;
}

.row-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
    gap: 16px;
    margin: 3.38vw auto 5.9vw auto;
}

/* .row-box div {
 
  transition: transform 0.3s ease-in-out;
} */

.suitable-left-box {
    flex: 1;
}

.suitable-right-box {
    flex: 1.78;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 13px;
}



.suitable-right-bottom-box {
    display: flex;
    justify-content: space-between;
}

.row-box div img {
    width: 100%;
    height: 100%;
}

.suitable-right-bottom-box img {
    width: 48% !important;
}

/* .row-box div:hover {
  transform: translateY(-20px);
} */

.bgF6F6F6 {
    background-color: #f6f6f6;
}

.single-box {
    width: 64vw;
    margin: 0 auto 0 auto;
    padding-bottom: 4.58vw;
}

.single-box .title {
    text-align: left;
    margin-bottom: 20px;
    font-size: 1.46vw;
    font-weight: 600;
    color: #000000;
    line-height: 1.77vw;
}

.cases {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 22px;
    justify-content: space-between;
}

.job-caces div {
    width: 24%;
}

.job-caces div img {
    width: 100%;
}

.img-box {
    margin: 0 auto;
    width: 62.5vw;
}

.carousel-row div {
    width: 19%;
}

.carousel-row div img {
    width: 100%;
}

.img-box img {
    width: 100%;
}

.company-pic-box div {
    width: 32%;
}

.company-pic-box div img {
    width: 100%;
}

.weight300 {
    font-weight: 300;
}

.mt90 {
    margin-top: 4.7vw;
}

.mt65 {
    margin-top: 3.4vw;
}

.carousel {
    width: 100%;
}

.carousel-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-image: url('./assets/student_work_bg.png'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 55px 10%;
}

.about-content {
    width: 33.85vw;
}

.about-content div {
    font-size: 1.35vw;
    font-weight: 600;
    color: #333333;
    line-height: 1.92vw;
    margin-bottom: 12px;
}

.nowrap {
    flex-wrap: nowrap;
}

.text-left {
    text-align: left;
}

.about-content span {
    font-size: 0.93vw;
    font-weight: 600;
    color: #333333;
    line-height: 1.66vw;
}

.year-box>div {
    width: 20%;
    padding-right: 4vw;
}

.year-box>div img {
    width: 100%;
}

.year {
    font-size: 1.56vw;
    font-weight: 600;
    color: #333333;
    line-height: 1.87vw;
    letter-spacing: 1px;
    margin-top: 1.87vw;
    margin-bottom: 3px;
}

.year-content {
    font-size: 0.72vw;
    font-weight: 300;
    color: #333333;
    line-height: 1.66vw;
}

.cooperatives-box {
    row-gap: 3.125vw;
}

.cooperatives-box div {
    width: 19%;
}


.cooperatives-box div img {
    width: 100%;
}

.why-choose-box div img {
    width: 100%;
}

.why-choose-box> :nth-child(1) {
    width: 57.5%;
}

.why-choose-box> :nth-child(2) {
    width: 40%;
}

.why-choose-box> :nth-last-child(-n+3) {
    /* 样式规则 */
    width: 32%;
}

.bannerBox .carousel-control-prev {
    left: 10% !important;
}

.carousel-control-prev-icon {
    background-image: url('./assets/banner/btn_banner_flipleft.png') !important;
}

.bannerBox .carousel-control-next {
    right: 10% !important;
}

.carousel-control-next-icon {
    background-image: url('./assets/banner/btn_banner_flipright.png') !important;
}

.job-btn {
    width: 11.4vw;
    margin: 0 auto;
}

.job-btn img {
    width: 100%;
}

.pc {
    display: block;
}

.pc-flex {
    display: flex;
}

.mobile {
    display: none;

}

@media (max-width: 600px) {
    .mobile-logo {
        width: 100%;
    }

    .App-header {
        padding: 0.93vw 2vw;
        justify-content: space-between;
    }

    .head-title {
        font-size: 8px;
        margin-right: 2vw;
        color: #fff;
    }

    .primary-btn {
        width: 34vw;
        height: 36px;
    }

    .pc {
        display: none;
    }

    .pc-flex {
        display: none;
    }

    .mobile {
        display: block;
    }

    .year {
        line-height: 3.87vw;
    }

    .year-content {
        line-height: 3.66vw;
    }

    .hot-class-box div img {
        bottom: -1px;
    }


    #wx {
        width: 334px;
        height: 312px;
        left: 50%;
        z-index: 1000;
        position: relative;
        background-repeat: no-repeat;
        background-size: 100% 274px;
        top: 50%;
        margin-left: -167px;
        margin-top: -156px;
    }

    #wx div {
        margin-left: -12.5px;
    }

    #wx .close {
        width: 25px;
        height: 25px;
        cursor: pointer;
    }

    .title_box span {
        font-size: 18px;
    }

    .single-box {
        width: 90vw;

    }

    .row-box,
    .img-box {
        width: 90vw;
    }

    .hot-class-box div img {
        border-radius: 5px;
    }

    .hot-class-box div:hover {
        margin-top: -10px;
    }

    .jobs {
        width: 90vw;
        flex-direction: column;
    }

    .jobs .left_box {
        flex-direction: row;
    }

    .studys {
        width: 90vw;
        gap: 5vw;
    }

    .teacher_carousel {
        width: 90vw;
    }

    .about_us_box {
        width: 90vw;
        left: 5vw;
    }

    .about_us_content {
        width: 65vw;
    }

    .about_us_content div {
        font-size: 6px;
        line-height: 8px;
    }

    .study_title {
        font-size: 8px;
    }

    .study_subtitle {
        font-size: 6px;
    }

    .App-header img {
        height: 28px;
    }

    .carousel-indicators {
        margin-bottom: 0 !important;
    }
}